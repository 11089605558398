<template>
  <div class="newslist">
    <Header/>
    <div class="topbox">
      <img src="../../../assets/topbannerimg.png" alt="">
    </div>
    <div class="alllist">
      <div class="listtitle w">
        <div class="toptit">
          <img class="iconleft" src="../../../assets/left.png" alt="">
          <img class="gonggao" src="../../../assets/gonggao.png" alt="">
          <img class="iconleft" src="../../../assets/right.png" alt="">
        </div>
        <div class="listallitem">
          <div class="listitem" v-for="(item,index) in items" :key="index" @click="jumpdetail">
            <div class="itemleft">
              <div class="time">{{item.time}}</div>
              <div class="data">{{item.data}}</div>
            </div>
            <div class="itemright">
              {{item.title}}
            </div>
          </div>
        </div>
        <div class="pagin">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="items.length">
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  data () {
    return {
      currentPage4: 1,
      items: [
        {
          time: '13',
          data: '2022/06',
          title: '【置顶】关于开展第五届“闪亮的日子——青春该有的模样”大学生就业创业人物事迹征集的通知'
        },
        {
          time: '13',
          data: '2022/06',
          title: '关于开展第五届“闪亮的日子——青春该有的模样”大学生就业创业人物事迹征集的通知'
        },
        {
          time: '13',
          data: '2022/06',
          title: '关于开展第五届“闪亮的日子——青春该有的模样”大学生就业创业人物事迹征集的通知'
        },
        {
          time: '13',
          data: '2022/06',
          title: '关于开展第五届“闪亮的日子——青春该有的模样”大学生就业创业人物事迹征集的通知'
        },
        {
          time: '13',
          data: '2022/06',
          title: '关于开展第五届“闪亮的日子——青春该有的模样”大学生就业创业人物事迹征集的通知'
        }
      ]
    }
  },
  methods: {
    handleSizeChange (val) {

    },
    handleCurrentChange (val) {

    },
    jumpdetail () {
      this.$router.push({
        path: '/newsdetails'
      })
    }
  },
  components: {
    Footer, Header
  }
}
</script>

<style scoped lang="less">
.newslist{
  min-width: 1200px;
  .topbox{
    height: 420px;
    width: 100%;
    img{
      height: 100%;
      width: 100%;
    }
  }
  .alllist{
    width: 100%;
    min-height: 600px;
    background-color: #F7FAFF;
    background-image: url(../../../assets/listbgimg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .listtitle{
      padding-top: 40px;
      .toptit{
        display: flex;
        justify-content: center;
        align-items: center;
        .gonggao{
          width: 117px;
          height: 24px;
          margin: 0 30px;
        }
        .iconleft{
          width: 149px;
          height: 17px;
        }
      }
      .listallitem{
        width: 100%;
        margin-top: 20px;
        .listitem{
          width: 100%;
          height: 100px;
          padding: 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(151, 151, 151, 0.2);
          cursor: pointer;
          .itemleft{
            text-align: center;
            border-top: 4px solid #004D9B;
            .time{
              font-size: 30px;
              color: #004D9B;
              font-weight: bold;
            }
            .data{
              font-size: 14px;
              color: #666;
            }
          }
          .itemright{
            margin-left: 24px;
            font-size: 16px;
            color: #333333;
          }
          &:hover{
            background-image: url(../../../assets/hoverbgimg.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border-bottom: none;
            border-radius: 0 0 0 15px;
            .itemleft{
              border-top-color: #fff;
              .time{
                color: #fff;
              }
              .data{
                color: #fff;
              }
            }
            .itemright{
              color: #004D9B;
              font-size: 18px;
              // font-weight: bold;
            }
          }
        }
      }
      .pagin{
        text-align: right;
        padding: 20px 0 60px;
        /deep/.el-pager .active{
          color: #004D9B;
        }
      }
    }
  }
}
</style>
